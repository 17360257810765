import React from 'react'

import HeaderTwo from 'sharedComponents/HeaderTwo'
import HeaderThree from 'sharedComponents/HeaderThree'
import Button from 'sharedComponents/Button'
import Text from 'sharedComponents/Text'
import style from './style.module.scss'

type Props = {
  onClose: () => void
  hasSubscription: boolean
  status: string
}

function IntellitagCTA(props: Props) {
  const { onClose, hasSubscription, status } = props

  return (
    <div className={style.wrapper}>
      <button onClick={onClose} className={style.closeButton}>
        Close
      </button>
      <HeaderTwo className={style.header}>
        Intellitag<sup>&reg;</sup>
      </HeaderTwo>
      <HeaderThree className={style.subHeader}>
        The Smart Pet Tag for Smart Pet Parents<sup>&trade;</sup>
      </HeaderThree>

      {hasSubscription ? (
        <>
          <Text className={style.text}>
            Intellitag protection for every household pet is included in your
            Pet Notify subscription.
          </Text>
          <Button className={style.button} to={(status==='premium') ? '/order-intellitag' :((status==='downgraded')?'/downgraded':((status==='past-due')?'/past-due':'/'))}>
            Order Intellitag
          </Button>
        </>
      ) : (
        <>
          <ul className={style.list}>
            <li>24/7 lost pet notifications</li>
            <li>Email, text and phone alerts when your lost pet is found</li>
            <li>Emergency contact backup alerts</li>
            <li>Unlimited care instructions to your pet’s rescuer</li>
            <li>Intellitag protection for every household pet</li>
            <li>Lifetime warranty and replacement</li>
          </ul>
          <Button className={style.button} to='/settings/upgrade'>
            Upgrade to premium
          </Button>
        </>
      )}

      <Text className={style.note}>Free engraving</Text>
    </div>
  )
}

export default IntellitagCTA
